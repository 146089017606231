import '@babel/polyfill';
import './main.scss';
import $ from 'jquery';
// import $h from './UIComponent';
// import Swiper from 'swiper/dist/js/swiper';

// window.$h = $h;
window.$ = $;
window.jQuery = $;
// window.Swiper = Swiper;
$(function () {
    let $bannerTitle = $('.banner-title'),
        $t1 = $($bannerTitle[0]),
        $t2 = $($bannerTitle[1]),
        $t3 = $($bannerTitle[2]),
        $t4 = $($bannerTitle[3]);
    +function oneCircle(){
        $t1.addClass('active');
        setTimeout(function () {
            $t1.removeClass('active');
            setTimeout(function () {
                $t2.addClass('active');
                setTimeout(function () {
                    $t2.removeClass('active');
                    setTimeout(function () {
                        $t3.addClass('active');
                        setTimeout(function () {
                            $t3.removeClass('active');
                            setTimeout(function () {
                                $t4.addClass('active');
                                setTimeout(function () {
                                    $t4.removeClass('active');
                                    setTimeout(function () {
                                        oneCircle();
                                    },1000);
                                },4000);
                            },1000);
                        },4000);
                    },1000);
                },4000);
            },1000);
        },4000);
    }();
});
